<!--  -->
<template>
  <div>

           <div class="table-info-header2">
              <div class="month" @click="selectMonth()"><span>{{activemonth}}</span><span class="yue">月</span><img src="~@/assets/images/distributionIndex/downIcon.png" alt=""></div>
              <div class="totalMoney">
                    <!-- <p style="margin-bottom: 8px;"><span>已激活</span><span class="numActived">23123张</span></p>
                    <p><span>未激活</span><span class="numNotActived">234张</span></p> -->
                    <div >
         
                        <p>总提现</p>
                    </div>
                    <div class="num">
                        <p class="numNotActived">￥22222</p>
                    </div>
              </div>
            </div>

  </div>
</template>

<script>


export default {
  name: '',
  props:{
    activemonth:{
        type:Number,
        default:8
    },
    extendNumL:{
        type:Object,
        default:()=>{
          return {}
            }
    }
  },
  data(){
     return {
            
            }

        },
  mounted(){},
  methods:{
    selectMonth(){

        this.$emit('selcetmonth')
    }
  }
  };
</script>
<style scoped lang="less">
 .table-info-header2{
        width: 100%;
        height: 59px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        background-color: #e1ecff;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        padding: 0 14px;
        align-items: center;
        color: #333333;
        // margin-top: 10px;
        .month {
          font-size: 12px;
              color: #333333;
          span {
            font-size: 30px;
            margin-left: 4px;
            // margin-right: 4px;
          }
          .yue {
             font-size: 12px;
             margin-right: 4px;
          }
          img {
            width: 12px;
            height: 8px;
          }
        }
        .totalMoney{
          font-size: 14px;
          color: #333333;
          font-weight: 500;
          display: flex;
        //   flex-direction: column;
        //   justify-content: flex-end;
        .num{
            display: flex;
            flex-direction: column;
            // justify-content: flex-end;
            align-items: flex-end;
          
        }
        p{
            color: #333333;
         
        }
          .numActived,.numNotActived {
                color: #333;
                margin-left: 5px;
                
            }
        }

      }
</style>