<!--  -->
<template>
  <div class="box">
    <van-nav-bar
        title="明细"
        left-arrow
        @click-left="onClickLeft"
        />

    <div class="content">
      <selectMonth></selectMonth>
      <div class="p">提现明细 ></div>
      <div class="list">

          <div class="list-item">
            <div class="iconbox">
              <img src="~@/assets/images/distributionIndex/bankCard.png" alt="">
            </div>
            <div  class="infobox">
                <div class="title"><span>提现到银行卡**999099</span><span>-￥23131</span></div>
                <div class="time">2022.2.2</div>

            </div>
          </div>



      </div>








    </div>

  </div>
</template>

<script>
import selectMonth from './components/selectMonth.vue';
export default {
  name: '',
  components:{
      selectMonth
  },
  data(){
     return {
             
            }

        },
  mounted(){},
  methods:{
    onClickLeft(){
           this.$router.go(-1)
    }
  }
  };
</script>
<style scoped lang="less">
.box{
   background-color: #f7f8f9;
   min-height: 100vh;
}
.content{

  padding: 12px;
  // background-color: #f7f8f9;
  box-sizing: border-box;
  .p {
        background-color: #fff;
        width: 100%;height: 40px;line-height: 40px;
        color: #333333;font-weight: 500;
        padding-left: 14px;
        box-sizing: border-box;
  }
  .list {
    width: 100%;
    min-height: 100px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #fff;

    .list-item {
      width: 100%;
      height: 80px;
      // background-color: aquamarine;
      padding: 0 14px;
      box-sizing: border-box;

      display: flex;
      // justify-content: space-between;
      .iconbox {
        width: 24px;
        height: 24px;
        // background-color: wheat;

        margin-right: 14px;
        margin-top: 18px;
        img {
          width: 24px;
          height: 18px;
        }

      }
      .infobox {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        border-bottom: 1px solid #EDEDED;
        padding: 20px 0;
        .title {
          font-size: 14px;
         display: flex;
         justify-content: space-between; 
        }
        .time {
          color: #ccc;
          font-size: 12px;
          margin-top: 10px;
        }
      }
    }
  }
}
</style>